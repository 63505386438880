import GlobalHandler from './classes/GlobalHandler';
import NodeEmitter from './classes/NodeEmitter';
import CookieBanner from './components/CookieBanner';

import Experience from './components/Canvas/Experience';
import Navigation from './components/Navigation';
import Loader from './components/@avery-loader/Loader';

import HomePage from './pages/Home';

import Lenis from '@studio-freight/lenis';

import { debounce, requestIdleCallbackPolyfill } from './utils/utils';
import DesignsPage from './pages/Designs';
import ScrollTrigger from 'gsap/all';
import WebsitesPage from './pages/Websites';
import NotFound from './pages/NotFound';
import ContactPage from './pages/Contact';
import Footer from './components/Footer';

import data from '../data/home.json';
import posthog from 'posthog-js';

class App {
	constructor() {
		this.isCreated = false;
		this.scrollOnLoad = null;
		console.log(data);
		this.create();
		this.createContent();
		this.createPreloader();
		this.createNavigation();
		this.registerNodeEvents();
	}

	create() {
		window.services = {
			// dev
			mobileOptimizedWebsite: true,
			advancedAnalytics: true,
			siteMaintenance: true,
			multipleLanguages: false,
			cms: false,
			ecommerce: false,
			// des
			web: true,
			visual: true,
			logo: false,
			brand: false,
			packaging: false,
			illustrations: false,
			threed: false,
		};
		console.log(window.services);
		requestIdleCallbackPolyfill();

		// Scroll
		this.scroll = new Lenis({
			// easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
		});
		this.scroll.on('scroll', this.updateMousePos.bind(this));

		// this.scroll.on('scroll', ScrollTrigger.update);
		// this.scroll.on('scroll', (e) => console.log(e));
		// Canvas
		this.experience = new Experience('.webgl');

		// Other
		this.addEventListeners();
		this.update();
	}

	createPreloader() {
		this.loader = new Loader({
			pages: this.pages,
			onLeave: () => {
				this.scroll.destroy();
				this.footer.destroy();
				// this.experience.destroy();
				this.navigation.closeAll();
				NodeEmitter.emit('hideMenu');
				GlobalHandler.handleDestroy(); // Runs destroy() on each component
			},

			onEnter: () => {
				GlobalHandler.setTemplate = this.loader.template;
			},

			afterEnter: () => {
				this.experience.createLogoScene();

				GlobalHandler.handlePageTemplate(); // sets this.template on all pages

				GlobalHandler.handleCreate(); // Run create() on each page
				GlobalHandler.handleResize(); // Runs onResize() on each component

				this.scroll = new Lenis();
				this.scroll.on('scroll', this.updateMousePos.bind(this));

				this.scroll.scrollTo('top');
				this.footer.create();

				// if (this.loader.template === 'home') {
				// 	NodeEmitter.emit('hideMenu');
				// } else {
				// }
			},
		});

		this.loader.on('scrollTo', (e) => {
			// e looks like '.awards'

			// const value = document.querySelector(e).getBoundingClientRect().top;
			if (this.scroll) this.scroll.scrollTo(e);
		});

		this.loader.on('scrollOnLoad', (e) => {
			this.scrollOnLoad = e;
		});

		GlobalHandler.handlePageTemplate();

		this.experience.createLogoScene();

		GlobalHandler.handleCreate();
		GlobalHandler.handleResize(); // Runs onResize() on each component

		this.loader.preloader.createLoader('home');
		this.loader.preloader.on('completed', this.onPreloaded.bind(this));
	}

	createNavigation() {
		this.navigation = new Navigation();

		if (this.loader.template !== 'home') this.navigation.show();
	}

	createContent() {
		// Pages
		this.mainDiv = document.querySelector('.main-div');

		this.home = new HomePage('.cover');
		this.designs = new DesignsPage('.designs');
		this.websites = new WebsitesPage('.websites');
		this.notfound = new NotFound('.notfound');
		this.contact = new ContactPage('.contact');

		this.pages = [
			{ page: this.home, url: ['/', '/home'] },
			{ page: this.designs, url: '/designs' },
			{ page: this.websites, url: '/websites' },
			{ page: this.contact, url: '/contact' },
			{ page: this.notfound, url: '/404' },
		];

		this.pageLength = this.mainDiv.getBoundingClientRect().height;
		this.isCreated = true;

		this.footer = new Footer();
		this.footer.create();

		this.cookieBanner = new CookieBanner();
		this.cookieBanner.on('analytics:initialize', () => {
			console.log('analytics:initialize');

			const cookieAllowed = localStorage.getItem('cookieAllowed');
			window.cookiedAllowed = cookieAllowed === 'true';

			posthog.init('phc_n76IBu71hBCGW5mx8Pv5MrAB65OYaMsqkfRSqqY1XCS', {
				api_host: 'https://eu.i.posthog.com',
				person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
				// debug: true,
			});
			posthog.set_config({
				persistence: 'localStorage+cookie', // 'memory' when not allowed?
			});
			// posthog.opt_in_capturing();
		});

		this.cookieBanner.on('analytics:reject', () => {
			// posthog.opt_out_capturing();

			console.log('analytics:reject');
		});
	}

	registerNodeEvents() {
		NodeEmitter.on('openMenu', () => {
			this.navigation.openMenu();
		});

		NodeEmitter.on('closeMenu', () => {
			this.navigation.closeMenu();
		});

		NodeEmitter.on('showMenu', () => {
			this.navigation.show();
		});
		NodeEmitter.on('hideMenu', () => this.navigation.hide());

		NodeEmitter.on('stopScroll', () => this.scroll.stop());
		NodeEmitter.on('startScroll', () => this.scroll.start());
	}

	onPreloaded() {
		GlobalHandler.handleResize();
		if (this.scrollOnLoad) {
			this.scroll.scrollTo(this.scrollOnLoad);
			this.scrollOnLoad = null;
		}
		this.loader.preloader.hide();
		this.navigation.updateNav(this.loader.template);
		NodeEmitter.emit('showMenu');
		// if (this.loader.template === 'home')
		// 	this.home.components.selection.getBounds();
		if (this.loader.template === 'designs') {
			this.designs.components.designProcess.on('stopScroll', () =>
				this.scroll.stop()
			);
			this.designs.components.designProcess.on('startScroll', () =>
				this.scroll.start()
			);
		}
	}

	update(time) {
		if (this.scroll) {
			this.scroll.raf(time);
			// this.scroll.on('scroll', ScrollTrigger.update);
		}

		if (this.experience && this.experience.isReady) this.experience.update();
		if (this.home && this.home.update) this.home.update();
		if (this.designs && this.designs.update) this.designs.update();

		this.frame = window.requestAnimationFrame(this.update.bind(this));
	}

	updateMousePos(e) {
		if (this.designs.isReady) {
			this.designs.updateMousePos(e);
		}
	}

	addEventListeners() {
		// @TODO handle all touch events here, and send values to buttons & canvas
		window.addEventListener('resize', debounce(this.onResize.bind(this))); // runs on the next frame

		// this.scroll.on('scroll', (e) => {
		// 	this.scrollPosition = 1.3 + window.scrollY / this.pageLength;
		// 	console.log(this.scrollPosition);
		// 	this.experience.camera.el.position.z = this.scrollPosition;
		// 	this.experience.speed = e.velocity * 0.002;
		// });
	}

	onResize() {
		GlobalHandler.handleResize();
	}
}

new App();
