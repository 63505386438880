import Component from '../classes/Component';
import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
export default class Footer extends Component {
	constructor() {
		super({
			element: 'footer',
			elements: {
				top: '.footer__top',
				container: '.footer__container',
				items: '.footer__item',
				getStarted: {
					wrapper: '.get__started',
					flexEls: '.get__started__flex',
				},
			},
		});
		this.inVision = false;
		this.x = 0;
	}

	create() {
		super.createComponent();

		this.createTimeline();
		this.createMarquee();
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.elements.top,
				start: 'top bottom',
				end: 'top top',
				toggleActions: 'restart none none reverse',
				onEnter: () => {
					this.inVision = true;
					this.update();
				},
				onLeaveBack: () => (this.inVision = false),
			},
		});

		this.timeline.fromTo(
			this.elements.items,
			{
				yPercent: 10,
				opacity: 0,
			},
			{ yPercent: 0, duration: 1, opacity: 1, stagger: 0.3, ease: 'power4.out' }
		);
	}

	createMarquee() {
		this.x = 0;
		this.totalWidth =
			this.elements.getStarted.flexEls[0].offsetWidth *
			this.elements.getStarted.flexEls.length;
		this.elements.getStarted.wrapper.style.width = `${this.totalWidth}px`;
	}

	onResize() {
		this.createMarquee();
	}

	update() {
		if (!this.inVision) return;

		this.elements.getStarted.wrapper.style.transform = `translateX(-${this.x}px)`;
		this.x++;

		if (this.x >= this.totalWidth / 2) {
			this.x = 0;
		}

		this.frame = requestAnimationFrame(() => this.update());
	}

	destroy() {
		super.destroy();
		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;

			this.inVision = false;
			setTimeout(() => (this.inVision = false), 1000);
		}
	}
}
