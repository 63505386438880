import Page from '../../classes/Page';
import each from 'lodash/each';
import ContactForm from './form';

export default class ContactPage extends Page {
	constructor(el) {
		super({
			element: el,
			elements: {
				singlelines: '.contact__form-input',
				textareas: '.contact__form-textarea',
				checkboxservices: '.checkbox__input--services',
				checkboxgroup: '.checkbox__group',
				webdev: '.checkbox__subwrapper--webdev',
				webdes: '.checkbox__subwrapper--webdes',
			},
		});
		this.id = 'contact';
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		if (!this.isCreated) {
			this.components = {
				form: new ContactForm({
					url: 'https://submitform-3kp2vg3t2a-uc.a.run.app',
				}),
			};
			this.isCreated = true;
		}

		this.checkFields();
		this.addEventListeners(); // @TODO DOUBLE CHECK
		// Create Components
		each(this.components, (component) => {
			component.create();
		});
		console.log(`🔼 ${this.id} is created`);
	}

	checkFields() {
		each(this.elements.checkboxservices, (element) => {
			const name = element.getAttribute('name');
			if (window.services[name]) {
				element.checked = true;
			}
		});
	}

	show() {}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	addEventListeners() {
		each(this.elements.checkboxgroup, (element) => {
			element.addEventListener('input', ({ target }) => {
				if (target) {
					const name = target.getAttribute('name');
					if (name === 'webdev') {
						this.elements.webdev.classList.toggle('open');
					}
					if (name === 'webdes') {
						this.elements.webdes.classList.toggle('open');
					}
				}
			});
		});
		each(this.elements.checkboxservices, (element) => {
			element.addEventListener('input', ({ target }) => {
				const name = target.getAttribute('name');
				const targetValue = target.checked;
				window.services[name] = targetValue;
			});
		});
		each(this.components, (component) => {
			component.addEventListeners();
		});

		each(this.elements.singlelines, (element) => {
			element.addEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-input--filled');
				} else {
					element.classList.remove('contact__form-input--filled');
				}
			});
		});
		each(this.elements.textareas, (element) => {
			element.addEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-textarea--filled');
				} else {
					element.classList.remove('contact__form-textarea--filled');
				}
			});
		});
	}

	removeEventListeners() {
		each(this.components, (component) => {
			component.removeEventListeners();
		});

		each(this.elements.singlelines, (element) => {
			element.removeEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-input--filled');
				} else {
					element.classList.remove('contact__form-input--filled');
				}
			});
		});

		each(this.elements.textareas, (element) => {
			element.removeEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-textarea--filled');
				} else {
					element.classList.remove('contact__form-textarea--filled');
				}
			});
		});
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		each(this.components, (component) => {
			component.destroy();
		});
	}
}
