import Component from '../classes/Component';
import GSAP from 'gsap';
import { setCookie, getCookie } from '../utils/cookie';
export default class CookieBanner extends Component {
	constructor() {
		super({
			element: '.cookies',
			elements: {
				description: '.cookies__description',

				button: {
					accept: '.cookies__button--accept',
					leave: '.cookies__button--leave',
				},
			},
		});

		this.create();
	}

	create() {
		console.log(`CREATE 💣`);
		const cookieAllowed = getCookie('av-cookies-allowed');

		window.cookiedAllowed = cookieAllowed === '1';

		if (window.cookiedAllowed) {
			this.hide();
			setTimeout(() => this.allow(), 1000);
			return;
		}

		this.show();
		this.addEventListeners();
	}

	hide(onCompleteProp) {
		GSAP.to(this.element, {
			autoAlpha: 0,
			duration: 0.3,
			ease: 'expo.out',
			onComplete: () => {
				if (onCompleteProp) {
					onCompleteProp();
				}
			},
		});
	}
	show() {
		GSAP.fromTo(
			this.element,
			{
				yPercent: 100,
			},
			{
				yPercent: 0,
				duration: 1.25,
				delay: 2,
				ease: 'expo.out',
			}
		);
	}

	allow() {
		console.log(`ALLOW 💣`);
		setCookie('av-cookies-allowed', '1', 365);
		window.cookiedAllowed = true;
		this.emit('analytics:initialize');

		this.hide(this.removeElementFromDOM());
	}

	reject() {
		console.log(`REJECT 💣`);

		this.emit('analytics:reject');
		this.hide(this.removeElementFromDOM());
	}

	addEventListeners() {
		this.elements.button.accept.addEventListener(
			'click',
			this.allow.bind(this)
		);

		this.elements.button.leave.addEventListener(
			'click',
			this.reject.bind(this)
		);
	}

	removeElementFromDOM() {
		this.element.remove();
	}
}
