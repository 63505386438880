import axios from 'axios';
import Component from '../../classes/Component';
import GSAP from 'gsap';

export default class ContactFormHome extends Component {
	constructor({ url = 'submitform' }) {
		super({
			element: '.contact__form__pricing',
			elements: {
				btn: '.btn',
			},
		});

		this.formUrl = url;
		this.timer = null;
		this.timeSteps = 0;
		this.reqAttempts = 0;
	}

	create() {
		super.createComponent();
		console.log(this.element);
	}

	errorTimer() {
		if (this.reqAttempts > 2) {
			this.elements.btn.textContent = 'Try again later';
			return;
		}
		this.timer = setInterval(() => {
			this.timeSteps++;
			if (this.timeSteps > 5) {
				clearInterval(this.timer);
				this.animate('default');
				this.reqAttempts += 1;
				this.timer = null;
				this.timeSteps = 0;
			} else {
				this.elements.btn.textContent = `Error! (${6 - this.timeSteps})`;
			}
		}, 1000);
	}

	animate(e) {
		this.elements.btn.classList.add('btn--disabled');
		switch (e) {
			case 'loading':
				this.elements.btn.textContent = 'Loading...';
				break;
			case 'success':
				this.elements.btn.textContent = 'Success!';
				this.elements.btn.classList.add('btn--success');
				break;
			case 'error':
				this.elements.btn.textContent = 'Error!';
				this.elements.btn.classList.add('btn--error');
				this.errorTimer();
				break;
			case 'default':
				this.elements.btn.classList.remove('btn--disabled');
				this.elements.btn.classList.remove('btn--success');
				this.elements.btn.classList.remove('btn--error');
				this.elements.btn.textContent = 'Submit Form';
				break;
			default:
				break;
		}
	}

	submitForm(e) {
		e.preventDefault();
		this.animate('loading');

		// Get data from form and add it to an object
		const finalData = {};
		const formData = new FormData(this.element);

		[...formData].map((info) => {
			finalData[info[0]] = info[1];
		});

		console.log(finalData);

		if (!finalData.name || !finalData.email) {
			return this.animate('default');
		}

		// Send the object via axios
		this.sendData(finalData);
	}

	async sendData(data) {
		try {
			await axios.post(`${this.formUrl}`, data);
			this.animate('success');
		} catch (error) {
			console.error(error.message);
			this.animate('error');
		}
	}

	addEventListeners() {
		this.element.addEventListener('submit', (e) => this.submitForm(e));
	}

	removeEventListeners() {
		this.element.removeEventListener('submit', (e) => this.submitForm(e));
	}

	destroy() {
		GSAP.killTweensOf(this.elements.left);
	}
}
