import Component from '../../classes/Component';

import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);

import each from 'lodash/each';
import map from 'lodash/map';
import Lightbox from '../../components/Lightbox';

export default class Mdesign extends Component {
	constructor() {
		super({
			element: '.mdesign__gallery',
			elements: {
				items: '.mdesign__gallery__item',
				galleryLeft: '.mdesign__gallery-left',
				galleryRight: '.mdesign__gallery-right',
			},
		});

		this.lightbox = new Lightbox();
	}

	create() {
		console.log('HALLo');
		super.createComponent();
		// this.shuffleItems();

		this.lightbox.create(this.elements.items);
		this.addEventListeners();
	}

	adjustImagesHeight() {
		const leftItems = this.elements.galleryLeft.querySelectorAll('.mdesign__gallery__item');
		const rightItems = this.elements.galleryRight.querySelectorAll('.mdesign__gallery__item');

		each(leftItems, (item) => {
			item.style.height = 'auto';
		}
		);
		each(rightItems, (item) => {
			item.style.height = 'auto';
		}
		);
		// const marginTop = window.getComputedStyle(leftItems[0], null).getPropertyValue("margin-top");

		let heights = {
			leftItems: [],
			rightItems: [],
			leftTotal: 0,
			rightTotal: 0,
		}
		each(leftItems, (item) => {
			const height = item.getBoundingClientRect().height;
			heights.leftItems.push(height);
			heights.leftTotal += height;
		});
		each(rightItems, (item) => {
			const height = item.getBoundingClientRect().height;
			heights.rightItems.push(height);
			heights.rightTotal += height;
		});

		console.log(heights);
		if (heights.leftTotal > heights.rightTotal) {
			const heightDiff = heights.leftTotal - heights.rightTotal;
			rightItems[rightItems.length - 1].style.height = `${heights.rightItems[rightItems.length - 1] + heightDiff}px`;
			console.log(heightDiff);
			rightItems[leftItems.length - 1].firstElementChild.style.objectFit = 'cover';
		}
		else if (heights.rightTotal > heights.leftTotal) {
			const heightDiff = heights.rightTotal - heights.leftTotal;
			console.log(heightDiff);
			leftItems[leftItems.length - 1].style.height = `${heights.leftItems[leftItems.length - 1] + heightDiff}px`;
			leftItems[leftItems.length - 1].firstElementChild.style.objectFit = 'cover';
		}

	}

	shuffleItems() {
		// console.log(this.elements.items);
		// let leftItems = 7;
		// let rightItems = 7;
		// const items = this.elements.items;
		// // 1 item from left, 1 from right, repeat until all items are added
		// map(this.elements.items, (item, index) => {
		// 	if (index % 2 === 0) {
		// 		item.style.order = leftItems;
		// 		leftItems--;
		// 	} else {
		// 		item.style.order = rightItems;
		// 		rightItems--;
		// 	}
		// });
	}

	openLightbox(item, type = 'image') {
		console.log('Open Lightbox', this.isDragging);
		if (this.isDragging) return;
		this.lightbox.setActiveImage(item, type);
		this.lightbox.open();
	}

	addEventListeners() {
		console.log(
			'addEventListeners Mdesign',
		);
		each(this.elements.items, (item) => {
			item.addEventListener('click', () => {
				this.openLightbox(item);
			});
		});
	}

	onResize() {
		this.adjustImagesHeight();
	}
	removeEventListeners() {
		each(this.elements.items, (item) => {
			item.removeEventListener('click', () => {
				this.openLightbox(item);
			});
		});
	}

	destroy() {
		super.destroy();
	}
}
